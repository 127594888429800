import { ReadonlyURLSearchParams } from 'next/navigation';
import { i18n, type Locale } from '@/i18n';
import { queryStringConstants } from '@/constants/queryConstants';
import type { SearchParams } from '@/models/SearchParams';
import type { VenueProfileFactParams } from '@/components/card/VenueCard';
import { Categories } from '@/constants/hubPagesConstants';
import type { QueryParam } from '@/utils/urlBuilder';
import { encodeSearchTerm } from '@/utils/encodeDecodeUrl';
import UrlBuilder from './urlBuilder';

const LOCALE_SET = new Set(i18n.locales);
export const DEFAULT_MEASUREMENT_UNIT = 'IMPERIAL';
const EMPTY_STRING = '';

export type Params = {
  key: string;
  value: string;
};

export function addExistingParamsToUrl(urlBuilder: UrlBuilder, searchParms: SearchParams): void {
  const isPhoenix = searchParms.app === 'phoenix';
  // Only add eventId for Phoenix experience
  if (isPhoenix) {
    urlBuilder.addQueryParam(queryStringConstants.EVENT_ID, searchParms.eventId, false, true);
  }
  urlBuilder.addQueryParam(queryStringConstants.APP, searchParms.app, false, true);
  urlBuilder.addQueryParam(queryStringConstants.PAGE_NUMBER, searchParms.p, false, true);
  urlBuilder.addQueryParam(queryStringConstants.SORT, searchParms.sort, false, true);
  urlBuilder.addQueryParam(queryStringConstants.FILTER, searchParms.filter, false, true);
  urlBuilder.addQueryParam(queryStringConstants.MEASUREMENT_UNIT, searchParms.measurementUnit, false, true);
  urlBuilder.addQueryParam(queryStringConstants.LAT, searchParms.lat, false, true);
  urlBuilder.addQueryParam(queryStringConstants.LNG, searchParms.lng, false, true);
  urlBuilder.addQueryParam(queryStringConstants.GEO, searchParms.geo, false, true);
  urlBuilder.addQueryParam(queryStringConstants.RFP_ID, searchParms.rfpId, false, true);
  urlBuilder.addQueryParam(queryStringConstants.VIEW, searchParms.view, false, true);
  urlBuilder.addQueryParam(queryStringConstants.DISPLAY_DISTANCE, searchParms.displayDistance, false, true);
  urlBuilder.addQueryParam(queryStringConstants.UTM_MEDIUM, searchParms.utm_medium, false, true);
  urlBuilder.addQueryParam(queryStringConstants.UTM_SOURCE, searchParms.utm_source, false, true);
  urlBuilder.addQueryParam(queryStringConstants.UTM_CAMPAIGN, searchParms.utm_campaign, false, true);
  urlBuilder.addQueryParam(queryStringConstants.UTM_TERM, searchParms.utm_term, false, true);
  urlBuilder.addQueryParam(queryStringConstants.UTM_CONTENT, searchParms.utm_content, false, true);
  urlBuilder.addQueryParam(queryStringConstants.UTM_ID, searchParms.utm_id, false, true);
  urlBuilder.addQueryParam(queryStringConstants.CID, searchParms.cid, false, true);
  urlBuilder.addQueryParam(queryStringConstants.AI, searchParms.ai, false, true);
  urlBuilder.addQueryParam(queryStringConstants.HUB_CATEGORY, searchParms.hubCategory, false, true);
}

export function addVenueProfileFactParamsToUrl(
  urlBuilder: UrlBuilder,
  venueProfileFactParams: VenueProfileFactParams
): void {
  urlBuilder.addQueryParam(queryStringConstants.AD_CODE, venueProfileFactParams.adId, false, true);
  urlBuilder.addQueryParam(queryStringConstants.AD_POSITION, venueProfileFactParams.adPos, false, true);
  urlBuilder.addQueryParam(queryStringConstants.AD_TYPE, venueProfileFactParams.adType, false, true);
  urlBuilder.addQueryParam(
    queryStringConstants.SEARCH_RESULTS_RANK,
    venueProfileFactParams.searchResultsRank,
    false,
    true
  );
  urlBuilder.addQueryParam(queryStringConstants.SEARCH_TERM, venueProfileFactParams.searchTerm, false, true);
  urlBuilder.addQueryParam(queryStringConstants.IMAGE_RANK, venueProfileFactParams.imageRank, false, true);
  if (venueProfileFactParams.source !== 'venue_card') {
    urlBuilder.addQueryParam(queryStringConstants.SOURCE, venueProfileFactParams.source, false, true);
  }
  if (venueProfileFactParams.searchTerm) {
    urlBuilder.addQueryParam(
      queryStringConstants.SEARCH_TERM,
      encodeURIComponent(venueProfileFactParams.searchTerm),
      false,
      true
    );
  }
  urlBuilder.addQueryParam(queryStringConstants.TIMESTAMP, Date.now(), false, true);
}

// Adds existing query params & fact Params to the Venue Profile Ur
export function updateVenueUrl(venueUrl, searchParams, venueProfileFactParams, hubCategory) {
  let params: SearchParams = {
    app: searchParams?.get('app') ?? undefined,
    p: searchParams?.get('p') ?? undefined,
    sort: searchParams?.get('sort') ?? undefined,
    measurementUnit: searchParams?.get('measurementUnit') ?? undefined,
    lat: searchParams?.get('lat') ?? undefined,
    lng: searchParams?.get('lng') ?? undefined,
    geo: searchParams?.get('geo') ?? undefined,
    filter: searchParams?.get('filter') ?? undefined,
    eventId: searchParams?.get('eventId') ?? undefined,
    rfpId: searchParams?.get('rfpId') ?? undefined,
    view: searchParams?.get('view') ?? undefined,
    displayDistance: searchParams?.get('displayDistance') ?? undefined,
    cid: searchParams?.get('cid') ?? undefined,
    utm_medium: searchParams?.get('utm_medium') ?? undefined,
    utm_source: searchParams?.get('utm_source') ?? undefined,
    utm_campaign: searchParams?.get('utm_campaign') ?? undefined,
    utm_term: searchParams?.get('utm_term') ?? undefined,
    utm_content: searchParams?.get('utm_content') ?? undefined,
    utm_id: searchParams?.get('utm_id') ?? undefined
  };
  if (hubCategory) {
    params = { ...params, hubCategory };
  }
  const urlBuilder = new UrlBuilder();
  urlBuilder.addPath(venueUrl ?? '');
  addExistingParamsToUrl(urlBuilder, params);
  addVenueProfileFactParamsToUrl(urlBuilder, venueProfileFactParams);

  return new UrlBuilder().mergeLocationObject(urlBuilder.getLocationObject()).getFullUrl();
}

export function prepareUrl(
  urlPathname: string | null,
  searchParams?: ReadonlyURLSearchParams | URLSearchParams | null,
  ...newParams: Params[]
): string {
  if (urlPathname === null) {
    throw new Error('received null pathname');
  }

  const params = new URLSearchParams(searchParams === null ? undefined : searchParams);
  newParams?.map(param => params.set(param.key, param.value));
  return params.toString() ? `${urlPathname}?${params.toString()}` : urlPathname;
}

export function prepareNextUrl(appBasePath: string, urlPathname: string | null): string {
  if (urlPathname === null) {
    throw new Error('received null pathname');
  }

  const pathname = urlPathname.replace(appBasePath, EMPTY_STRING);
  return pathname;
}

/**
 * Swap or add nextLocale in the urlPathname passed to prepare a url for target locale
 */
export function prepareNextPath(appBasePath: string, urlPathname: string | null, nextLocale: Locale): string {
  if (!urlPathname) {
    throw new Error('urlPathname is null');
  }

  const segments = urlPathname.replace(appBasePath, EMPTY_STRING).split('/');
  if (segments.length > 1) {
    // locale in url, replace the locale segment with new one
    if (LOCALE_SET.has(segments[1] as Locale)) {
      segments[1] = nextLocale;
    } else {
      // locale not in url, insert an empty segment to add one.
      segments.unshift(EMPTY_STRING);
      segments[1] = nextLocale;
    }
  }
  return segments.join('/');
}

/**
 * Swap or add promotionId in the urlPathname passed to prepare a url for that promotion
 */
export function prepareNextPathForPromotion(
  appBasePath: string,
  urlPathname: string | null,
  promotionId: string
): string {
  if (!urlPathname) {
    throw new Error('urlPathname is null');
  }

  const segments = urlPathname.replace(appBasePath, EMPTY_STRING).split('/');
  const segementLength = segments.length;
  if (segments[segementLength - 1].startsWith('promotion-')) {
    segments[segementLength - 1] = `promotion-${promotionId}`;
  } else {
    segments.push(`promotion-${promotionId}`);
  }
  return segments.join('/');
}

/**
 * Check if the query param is valid
 */
export function isValidParam(param: string | null | undefined): boolean {
  return param !== null && typeof param !== 'undefined' && param !== '';
}

/**
 * Returns hub page path param element from URL path
 *
 * @param elements
 */
export function filterHubPagePathParam(elements: string[]): string | undefined {
  return elements.find(e => Array.from(Categories.values()).filter(f => f.path === e).length > 0);
}

// removes hub page path param from URL path
export function removeHubPageInUrl(existingPath: string): string {
  let elements = existingPath.split('/');

  // Check if we have any hub-pages path
  const element = filterHubPagePathParam(elements);

  // If we have hub-pages path then remove that path
  if (element) {
    elements = elements.filter(e => e !== element);
  }
  return elements.join('/');
}

// replaces url with the new location
export function updateSearchLocationInUrl(
  newTerm: string,
  existingPath: string,
  isHypheniseUrlEnabled: boolean
): string {
  // removes hub page path param from URL path
  const updatedPath = removeHubPageInUrl(existingPath);
  const elements = updatedPath.split('/');

  let searchTerm = encodeURIComponent(newTerm);
  if (isHypheniseUrlEnabled) {
    searchTerm = encodeSearchTerm(newTerm);
  }
  elements[elements.length - 1] = searchTerm;
  return elements.join('/');
}

/**
 * If the provided URL isn't an absolute one, update it to make it so!  This is to be used for external URLs where
 * the link comes from a user-provided value which has not been validated to ensure that a URL scheme is present.
 * It is also useful for cvent-hosted URLs in some instances where our services return those URLs without a scheme.
 * @param url - The URL which will only be updated if it is relative to begin with
 */
export default function makeUrlAbsolute(url) {
  if (url.indexOf('//') === -1) {
    // We can't know which scheme was intended for a given url so we don't specify one here
    return `//${url}`;
  }
  return url;
}

// Helper function to convert Record<string, string> to QueryParam
export function convertToQueryParam(query: Record<string, string>): QueryParam {
  const queryParam: QueryParam = {};
  for (const [key, value] of Object.entries(query)) {
    queryParam[key] = [value];
  }
  return queryParam;
}
