import type { IconId } from '@/components/shared/Icons';

type Category = {
  // encoded string URLs to route to hub pages
  imperialFilter: string;
  metricFilter: string;
  iconName: IconId;
  path: string;
  titleCategory: string;
  descriptionCategory: string;
  summaryCategory: string;
  summarySubHeaderCategory: string;
};

export const Categories: Map<string, Category> = new Map([
  /*
    "filters": [
          {
            "type": "DISPLAY_TYPES",
            "values": [
              "CSN_AVAILABLE",
              "CSN_NON_AVAILABLE"
            ]
          },
          {
            "type": "VENUE_TYPE",
            "values": [
              "BOUTIQUE_HOTEL",
              "CONFERENCE_CENTER",
              "EXPOSITION_CENTER",
              "HOTEL",
              "LUXURY_HOTEL",
              "RESORT",
              "RESTAURANT"
            ],
            "occurrenceType": "SHOULD"
          },
          {
            "type": "AMENITIES",
            "values": [
              "RESTAURANT_ON_SITE"
            ],
            "occurrenceType": "MUST"
          },
          {
            "type": "TOTAL_MEETING_SPACE",
            "minimum": 100.0,
            "maximum": null,
            "measurementUnit": "SQUARE_FEET"
          },
          {
            "type": "NUMBER_OF_SLEEPING_ROOMS",
            "minimum": 10.0,
            "maximum": null,
            "measurementUnit": "SQUARE_FEET"
          }
        ]
  */
  [
    'RESTAURANT',
    {
      imperialFilter:
        'eJxdjcFOwzAQRP9lzzmUU1Fu27AUq44dbCc0rSqrokaKaAIKCVVU5d9Zlwvi8qRdvZm5QkWqJO_qgiDdg9tuIIH19sKcdhPzeVkzyzdibvol8_7dwiGBB2ELifUtamM2s8pjhULiSkY73kr__XHKoBNqzf4Vmu71PJ5C2fXHIZyq0I3hC9KhH0MC38fz7dof5gSG6TNACopevCU02ROXY05KOPG7fDEfsdtph9LnRHHC2wIzijtt0zXt2EJ6t1hwmyrzFRmvH72VREVUjda5_afO8w9E2VPT',
      metricFilter:
        'eJxdjcFuwjAQRP9lzzm0J6rclrClFo4dbCclRchCxZWikhSFpChC_HvX9FL18qRdvZm5QkWqJO_qgiDdgtusIIHl5sKc3ibmelYzyw9irvoZ8-nTwi6BhbCFxPoetTGbWeWxQiFxLqMdb6X__jhl0Am1ZP8KTfd-HA-h7Pr9EA5V6MZwhnTox5DA9_54v7a7WwLDdAqQgqJXbwlN9sLlmJMSTvwuX8xX7HbaofQ5UZzwtsCM4k7bdE07tpA-PnCZKvM5Ga-fvZVERTSN1rn9b95-APaQU6M',
      iconName: 'utencils',
      path: 'restaurant-venues',
      summaryCategory: 'category_search_results_page_v2_venues_summary',
      summarySubHeaderCategory: 'category_search_results_page_v2_restaurant_venues_summary_sub_header',
      titleCategory: 'category_search_results_page_v2_restaurant_venues',
      descriptionCategory: 'category_search_results_page_v2_restaurant_venues_description'
    }
  ],

  /*
    "filters": [
          {
            "type": "DISPLAY_TYPES",
            "values": [
              "CSN_AVAILABLE",
              "CSN_NON_AVAILABLE"
            ]
          },
          {
            "type": "VENUE_TYPE",
            "values": [
              "CONFERENCE_CENTER",
              "CONVENTION_CENTER"
            ],
            "occurrenceType": "SHOULD"
          },
          {
            "type": "AMENITIES",
            "values": [
              "VIDEO_CONFERENCING"
            ]
          }
        ]
  */
  [
    'CONFERENCE',
    {
      imperialFilter:
        'eJxNTcsKgkAU_Zezdttmdje71YDdxFFDRERyhEBEfAQh_nujbVodzntBypJwGWchQ-VIGoaH00FQONAmDCjbTbO5vpGSUtIBHYMtt3G5_2uuFVGs5eLyC17ds51rm3RDNdk6td1sR6hpmK2Hd9XuLC9WD9Ont1AQfpSGKfKvbpxuLDrWv-fz2KNYv0oaM_Q',
      metricFilter:
        'eJxNTcsKgkAU_Zezdttmdje71YDdxFFDRERyhEBEfAQh_nujbVodzntBypJwGWchQ-VIGoaH00FQONAmDCjbTbO5vpGSUtIBHYMtt3G5_2uuFVGs5eLyC17ds51rm3RDNdk6td1sR6hpmK2Hd9XuLC9WD9Ont1AQfpSGKfKvbpxuLDrWv-fz2KNYv0oaM_Q',
      iconName: 'session',
      path: 'conference-centers',
      summaryCategory: 'category_search_results_page_v2_venues_summary',
      summarySubHeaderCategory: 'category_search_results_page_v2_conference_centers_summary_sub_header',
      titleCategory: 'category_search_results_page_v2_conference_centers',
      descriptionCategory: 'category_search_results_page_v2_conference_centers_description'
    }
  ],

  /*
    "filters": [
          {
            "type": "DISPLAY_TYPES",
            "values": [
              "CSN_AVAILABLE",
              "CSN_NON_AVAILABLE"
            ]
          },
          {
            "type": "VENUE_TYPE",
            "values": [
              "RESORT",
              "ENTERTAINMENT_VENUE",
              "BANQUET_FACILITY",
              "BAR_CLUB"
            ],
            "occurrenceType": "SHOULD"
          },
          {
            "type": "AMENITIES",
            "values": [
              "CASINO",
              "DANCE_FLOORS"
            ],
            "occurrenceType": "MUST"
          }
        ]
  */
  [
    'PARTY',
    {
      imperialFilter:
        'eJxNjUELgkAUhP_LO-8piNLb05Zcsc1cNRYRkVyikCVMAxH_e0-7dBmY4ZuZCXIuM16lOubgFnDZaWCQOSHpuN2TBo6FksFBqDhCvYJqIX0lK8xRROhFnLjFy_N_Rq0EUyGPxE_wsLd2aExmu7o3TW7sYN7g9t1gGHzqdnVFOTPox5cBFyS_Vopj4gc0jicuRSp-z3rjUXR_hlDOX30lN8o',
      metricFilter:
        'eJxNjUELgkAUhP_LO-8piNLb05Zcsc1cNRYRkVyikCVMAxH_e0-7dBmY4ZuZCXIuM16lOubgFnDZaWCQOSHpuN2TBo6FksFBqDhCvYJqIX0lK8xRROhFnLjFy_N_Rq0EUyGPxE_wsLd2aExmu7o3TW7sYN7g9t1gGHzqdnVFOTPox5cBFyS_Vopj4gc0jicuRSp-z3rjUXR_hlDOX30lN8o',
      iconName: 'event',
      path: 'social-and-party-venues',
      summaryCategory: 'category_search_results_page_v2_venues_summary',
      summarySubHeaderCategory: 'category_search_results_page_v2_social_and_party_venues_summary_sub_header',
      titleCategory: 'category_search_results_page_v2_social_and_party_venues',
      descriptionCategory: 'category_search_results_page_v2_social_and_party_venues_description'
    }
  ],

  /*
    "filters": [
          {
            "type": "DISPLAY_TYPES",
            "values": [
              "CSN_AVAILABLE",
              "CSN_NON_AVAILABLE"
            ]
          },
          {
            "type": "VENUE_TYPE",
            "values": [
              "CORPORATE_OFFICE_SPACE"
            ],
            "occurrenceType": "SHOULD"
          }
        ]
  */
  [
    'CORPORATE',
    {
      imperialFilter:
        'eJxNy9EKgjAYxfF3Odd7gt2t9VGDscTpwkSG5CBBRpgLQnz3bFdd_n-cs8KRqclXTUHgLR43iY7hqGyhRZPZ_lxa44UTSouDJrDc5vJv-6sUlTKnfb9ijPcpDaGOc7-EwYWYwgt8mVNgePdTrrbbGJbPM4DD0NVbEqU8Y_sCtnQtVA',
      metricFilter:
        'eJxNy9EKgjAYxfF3Odd7gt2t9VGDscTpwkSG5CBBRpgLQnz3bFdd_n-cs8KRqclXTUHgLR43iY7hqGyhRZPZ_lxa44UTSouDJrDc5vJv-6sUlTKnfb9ijPcpDaGOc7-EwYWYwgt8mVNgePdTrrbbGJbPM4DD0NVbEqU8Y_sCtnQtVA',
      iconName: 'office-building',
      path: 'corporate-and-business-venues',
      summaryCategory: 'category_search_results_page_v2_venues_summary',
      summarySubHeaderCategory: 'category_search_results_page_v2_corporate_and_business_venues_summary_sub_header',
      titleCategory: 'category_search_results_page_v2_corporate_and_business_venues',
      descriptionCategory: 'category_search_results_page_v2_corporate_and_business_venues_description'
    }
  ],

  /*
    "filters": [
        {
          "type":"DISPLAY_TYPES",
          "values":[
            "CSN_AVAILABLE",
            "CSN_NON_AVAILABLE"
          ]
        },
        {
          "type": "VENUE_TYPE",
          "values": [
            "BOUTIQUE_HOTEL",
            "CONFERENCE_CENTER",
            "EXPOSITION_CENTER",
            "HOTEL",
            "LUXURY_HOTEL",
            "RESORT"
          ],
          "occurrenceType":"SHOULD"
        },
        {
          "type": "TOTAL_MEETING_SPACE",
          "minimum": 10000.0,
          "maximum": null,
          "measurementUnit": "SQUARE_FEET"
        }
      ]
  */
  [
    'LARGE',
    {
      imperialFilter:
        'eJxNjMEKgkAURf_lrV3USpjdZA8TbTRnNC1kkJxAUAlzChH_vRlXvcWBe3nnLpAjy1CKMkEgdxBFCA5kTzQMR9fQL76G8202vLglVA4cA55EtNwkbi2PM0lzGkT0EFnTZhb_d8ZKqQiYb_4XaIdHpxuVDWM9qSZXg1ZvINOolQOfutvSvVodmOaXAgIMr5IjTb2TGRexoJE8I9o5yRPqod3s26HtdQ9kvzO3rj-hBEBx',
      metricFilter:
        'eJxNjEELgkAQRv_LnD3YSfC22WDitpq7miaySG4gqIRpIeJ_b9dTlwffMO-tkCFLUYoiRnBLEHkIFqRP1AxHR9PPv5rLfdG8OgVUFpwCHlNS7BI3lseZJBkJKDlSY5rNov-bthIiAubr_xXa4dHNjUqHsZ5Uk6lhVm9wp3FWFnzqbl9ltVkwLS8FLjC8SY4k8c46LiJBqLwgmpzkMfHQNPt2aPu5B_dg2_a2_QBhLUBB',
      iconName: 'floorplan',
      path: 'large-venues',
      summaryCategory: 'category_search_results_page_v2_venues_summary',
      summarySubHeaderCategory: 'category_search_results_page_v2_large_venues_summary_sub_header',
      titleCategory: 'category_search_results_page_v2_large_venues',
      descriptionCategory: 'category_search_results_page_v2_large_venues_description'
    }
  ],

  /*
    "filters": [
      {
        "type":"DISPLAY_TYPES",
        "values":[
          "CSN_AVAILABLE",
          "CSN_NON_AVAILABLE"
        ]
      },
      {
        "type": "AMENITIES",
        "values": [
          "OUTDOOR_SPACE"
        ],
        "occurrenceType": "MUST"
      }
    ]
  */
  [
    'OUTDOOR',
    {
      imperialFilter:
        'eJxNjssKgzAQRf9l1vkCd1Md2oBNxDxaEQlSsxBEio2FIv5707jp8h7m3jMbWBKGnG4qgqztGBRcVSU2iaiIIFfCoUVe4qkkYCkL-c9iq0bNxTnebzDOj2kdvJmXPvjB-nn1L8jCsnoG735Kqe12BuHz9JCBoJtThHV-ieN4JcE1P8z6bn_bx4cF6ehLXBpdSFk7VWEe7fsXw3c61w',
      metricFilter:
        'eJxNjssKgzAQRf9l1vkCd1Md2oBNxDxaEQlSsxBEio2FIv5707jp8h7m3jMbWBKGnG4qgqztGBRcVSU2iaiIIFfCoUVe4qkkYCkL-c9iq0bNxTnebzDOj2kdvJmXPvjB-nn1L8jCsnoG735Kqe12BuHz9JCBoJtThHV-ieN4JcE1P8z6bn_bx4cF6ehLXBpdSFk7VWEe7fsXw3c61w',
      iconName: 'sun',
      path: 'outdoor-venues',
      summaryCategory: 'category_search_results_page_v2_venues_summary',
      summarySubHeaderCategory: 'category_search_results_page_v2_outdoor_venues_summary_sub_header',
      titleCategory: 'category_search_results_page_v2_outdoor_venues',
      descriptionCategory: 'category_search_results_page_v2_outdoor_venues_description'
    }
  ],

  /*
    "filters": [
      {
        "type":"DISPLAY_TYPES",
        "values":[
          "CSN_AVAILABLE",
          "CSN_NON_AVAILABLE"
        ]
      },
      {
        "type": "TOTAL_MEETING_SPACE",
        "minimum": 5000.0,
        "maximum": null,
        "measurementUnit": "SQUARE_FEET"
      }
    ]
  */
  [
    'HOTEL',
    {
      imperialFilter:
        'eJxNjLEKgzAURf_lzRlcumR7tY9WSKOYaBGRIDVDQEOxplDEf2906ngO954VapIVGd0UBLztGFwyVQhsDqOiglRJgzVmAs-CgB0s838XXyXqTF7jfgXnn2MYbOXnfrFDbX2wb-DLHCyDTz8e1HYbg-X7ssBB0sMowjK9xbjONQpzJ9pzRhWY0t6cnHdTmICfkiTZth-JEjej',
      metricFilter:
        'eJxNjLEKgzAURf_lzRlcumR7tY9WSKOYaBGRIDVDQEOxplDEf2906ngO954VapIVGd0UBLztGFwyVQhsDqOiglRJgzVmAs-CgB0s838XXyXqTF7jfgXnn2MYbOXnfrFDbX2wb-DLHCyDTz8e1HYbg-X7ssBB0sMowjK9xbjONQpzJ9pzRhWY0t6cnHdTmICfkmTbflIJN3M',
      iconName: 'hotel',
      path: 'hotel-venues',
      summaryCategory: 'category_search_results_page_v2_venues_summary',
      summarySubHeaderCategory: 'category_search_results_page_v2_hotel_venues_summary_sub_header',
      titleCategory: 'category_search_results_page_v2_hotel_venues',
      descriptionCategory: 'category_search_results_page_v2_hotel_venues_description'
    }
  ],

  /*
    "filters": [
      {
        "type": "DISPLAY_TYPES",
        "values": [
          "CSN_AVAILABLE",
          "CSN_NON_AVAILABLE"
        ]
      },
      {
        "type": "VENUE_TYPE",
        "values": [
          "RESORT",
          "ENTERTAINMENT_VENUE",
          "BANQUET_FACILITY",
          "BAR_CLUB"
        ],
        "occurrenceType": "SHOULD"
      },
      {
        "type": "AMENITIES",
        "values": [
          "CASINO",
          "DANCE_FLOORS"
        ],
        "occurrenceType": "MUST"
      },
      {
        "type": "LARGEST_ROOM",
        "minimum": null,
        "maximum": 5000.0,
        "measurementUnit": "SQUARE_FEET"
      }
    ]
  */
  [
    'SMALL',
    {
      imperialFilter:
        'eJxNjsEKgkAQht9lznuQQEpvqy25oau5aoiISC5h6BKmkYjv3q5duvzwD9_MNwtkhKWkSvKIgF1Aap0BwWweVF72uUrPklAiOFIe-TjfQK5Jl7MKZ5j62PGJ4nRn4f9MbcU4oeyk-AVaeeumRqRyqEfRZEJO4gX2OEwCwbvutlaUK4JxfgqwgZFrxQmOXU8dxwFhNKE_8_2hf8x3jjb4OD4RnlRxGAZa09eftp96sE3DMNb1CwwLQVs',
      metricFilter:
        'eJxNjsEKgkAQht9lzh4ikHJvqy26oau5aoiISC5hqIS5kYjv3q5duvzwD9_MNwtkhKWkSvKIACogtc5gwGweVV4OuUrPGqA04ER55ON8A7kmHc4qnGHqY9snitOdhf8ztRXjhDJX8Qu0w62TjUiHsZ5Ek4lBihegaZTCgHfdba0oVwOm-SkAASPXihMcO546jgPCaEJ_5vtD_5jvbW3wcewSnlRxGAZa09eftpc9IHO3W9cvyztBKw',
      iconName: 'perimeter-seating',
      path: 'small-venues',
      summaryCategory: 'category_search_results_page_v2_venues_summary',
      summarySubHeaderCategory: 'category_search_results_page_v2_small_venues_summary_sub_header',
      titleCategory: 'category_search_results_page_v2_small_venues',
      descriptionCategory: 'category_search_results_page_v2_small_venues_description'
    }
  ]
]);
