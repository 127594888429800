// Being used in middleware (not running on node) so kept separate from urlHelper.ts since that contains lodash

/**
 * Transforms the search term from using commas and spaces to using hyphens.
 * For example, "Washington D.C., DC, USA" becomes "Washington-D.C.--DC--USA".
 * @param searchString
 * @returns
 */
export function encodeSearchTerm(searchString: string): string {
  return searchString.replaceAll('-', '~').replaceAll(', ', '--').replaceAll(' ', '-');
}

/**
 * Transforms the Encoded (hyphenated) search term
 * to the search term with commas and spaces.
 * For instance, "Washington-D.C.--DC--USA" becomes "Washington D.C., DC, USA".
 * @param searchString
 * @returns
 */
export function decodeSearchTerm(searchString: string): string {
  return searchString.replaceAll('--', ', ').replaceAll('-', ' ').replaceAll('~', '-');
}
